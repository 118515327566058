import axios from "axios";
export default {
    name: 'auth-mixin',
    
    data() {
        return { // Инициализация статуса авторизации
            
        };
    },
    methods: {
        logout() {
            var config = {
                method: 'post',
                url: process.env.VUE_APP_BASE_URL + '/logout',
                headers: {
                    'Accept': 'application/json'
                },
            };
            axios(config)
                .then(() => {
                    localStorage.setItem('isLogin', '0')
                    this.deleteCookie('adsquiz_session')
                    this.deleteCookie('XSRF-TOKEN')
                    // eslint-disable-next-line
                    HelpCrunch('logout')
                    this.$router.push({ name: 'login-view' })
                })
                .catch((error) => {
                    console.log(error);
                    localStorage.setItem('isLogin', '0')
                    this.deleteCookie('adsquiz_session')
                    this.deleteCookie('XSRF-TOKEN')
                    this.$router.push({ name: 'login-view' })
                });
        },
        setCookie(name, value, options = {}) {
            options = {
                path: '/',
                // при необходимости добавьте другие значения по умолчанию
                ...options
            };

            if (options.expires instanceof Date) {
                options.expires = options.expires.toUTCString();
            }

            let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

            for (let optionKey in options) {
                updatedCookie += "; " + optionKey;
                let optionValue = options[optionKey];
                if (optionValue !== true) {
                    updatedCookie += "=" + optionValue;
                }
            }

            document.cookie = updatedCookie;
        },
        deleteCookie(name) {
            this.setCookie(name, "", {
                'max-age': -1
            })
        },
        errorAuth(status) {
            if (status == 401) {
                this.logout();
            } else if (status == 419) {
                this.logout();
            }
        },

    },
    mounted() {
    },
}