<template>
    <header-vue @go-to-tariffs="goToTariffs()" @togle-profile-modal="toggleProfileModal()" @refresh-user-info="refreshUserInfo" :isAuthenticated="true"></header-vue>
    <main>
      <router-view :userInfo="userInfo"></router-view>
    </main>
    <footer-vue></footer-vue>
    <transition name="fade">
        <modal-profile-action v-show="isOpenedProfileModal" @togle-profile-modal="toggleProfileModal()" @open-deleted-quiz-modal="openDeletedQuizModal"/>
    </transition>
    <modal-deleted-quiz-popup v-show="isOpenedDeletedQuizModal" @close-deleted-quizzes-modal="closeDeletedQuizzesModal()" @restore-quiz-sended="restoreQuizSended()" :isOpenedDeletedQuizModal="isOpenedDeletedQuizModal" :userInfo="userInfo"/>
    <modal-restore-quiz-sended v-show="isOpenedRestoreQuizSendedQuizModal" @close-restore-quiz-sended-modal="closeRestoreQuizSendedModal()"/>
    <notifications position="top right" />
</template>

<script>
    import axios from "axios"
    // import WorkboardView from '@/views/WorkboardView.vue'
    import HeaderVue from '@/components/common/Header.vue'
    import FooterVue from '@/components/common/Footer.vue'
    import ModalDeletedQuizPopup from '@/components/common/ModalDeletedQuizPopup.vue'
    import ModalRestoreQuizSended from '@/components/common/ModalRestoreQuizSended.vue'
    import ModalProfileAction from '@/components/common/ModalProfileAction.vue'
    export default {
        name: "main-layout",
        components: {
            HeaderVue,
            FooterVue,
            ModalDeletedQuizPopup,
            ModalRestoreQuizSended,
            ModalProfileAction,
        },
        data() {
            return {
                baseBackendUrl: process.env.VUE_APP_BASE_URL,
                baseUserIdent: process.env.VUE_APP_USER_IDENT,
                isOpenedProfileModal: false,
                isOpenedDeletedQuizModal: false,
                isOpenedRestoreQuizSendedQuizModal: false,
                userInfo: {
                    id: "4",
                    email: "user@adsquiz.io",
                    name: "",
                    role: "user",
                    profile: "freelance",
                    phone: null,
                    password: null,
                    interface_lang: "",
                    tarifs: [
                        {
                            id: "4460",
                            name: "Optimal",
                            price: 20,
                            is_active: true,
                            start_at: 1686843394,
                            end_at: 1689435394
                        }
                    ],
                    payments: [],
                    created_at: 1663934006,
                    quizCounter: 0,
                    leadCounter: 0,
                    isBlocked: false,
                    bonuses: []
                },
            }
        },
        computed: {
        },
        methods: {
            changeLocale(locale) {
                this.$i18n.locale = locale
                localStorage.setItem('locale_lang', locale);
                this.updateUserLang(this.$i18n.locale)
                this.mountedLang(this.$i18n.locale)
            },
            updateUserLang(locale) {
                var data = JSON.stringify({
                    query: `mutation {
                        updateUser(
                            id: "` + this.userInfo.id + `",
                            interface_lang: "${locale}",
                        )
                        {
                            id
                            interface_lang
                        }
                    }`,
                    variables: {}

                });

                var config = {
                    method: 'post',
                    url: this.baseBackendUrl + '/graphql/',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    data: data
                };

                axios(config)
                    .then(() => {
                        
                    })
                    .catch (error => {
                        this.$notify({
                            title: 'Помилка в оновлені даних: "' + this.userInfo.email,
                            type: 'error'
                        });
                        console.log(error);
                    })
            },
            mountedLang(interfaceLang) {
                var htmlElement = document.getElementsByTagName('html')[0]
                htmlElement.setAttribute('lang', interfaceLang);
            },
            activeLang(locale) {
                let locale_lang = this.$i18n.locale
                if (locale == locale_lang) {
                    return 'active'
                } else {
                    return ''
                }
            },
            refreshUserInfo(userInfo) {
                this.userInfo = userInfo
            },
            toggleProfileModal() {
                this.isOpenedProfileModal = !this.isOpenedProfileModal
            },
            openDeletedQuizModal() {
                this.isOpenedDeletedQuizModal = !this.isOpenedDeletedQuizModal
            },
            closeDeletedQuizzesModal() {
                this.isOpenedDeletedQuizModal = false
            },
            restoreQuizSended() {
                this.isOpenedRestoreQuizSendedQuizModal = true
            },
            closeRestoreQuizSendedModal() {
                this.isOpenedRestoreQuizSendedQuizModal = false
            },
            goToTariffs() {
                this.$router.push({ name: 'tariffs-page' })
            },
            goToProfile() {
                this.$router.push('profile')
            },
            logout() {
                var config = {
                    method: 'post',
                    url: this.baseBackendUrl + '/logout',
                    headers: {
                        'Accept': 'application/json'
                    },
                };
                axios(config)
                    .then(() => {
                        localStorage.setItem('isLogin', '0')
                        // eslint-disable-next-line
                        HelpCrunch('logout');
                        this.$router.push({ name: 'login-view' })
                        this.deleteCookie('adsquiz_session')
                        this.deleteCookie('XSRF-TOKEN')
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            changeLocaleAfterLogin(interface_lang) {
                this.$i18n.locale = interface_lang
            },
            setCookie(name, value, options = {}) {
                options = {
                    path: '/',
                    // при необходимости добавьте другие значения по умолчанию
                    ...options
                };

                if (options.expires instanceof Date) {
                    options.expires = options.expires.toUTCString();
                }

                let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

                for (let optionKey in options) {
                    updatedCookie += "; " + optionKey;
                    let optionValue = options[optionKey];
                    if (optionValue !== true) {
                        updatedCookie += "=" + optionValue;
                    }
                }

                document.cookie = updatedCookie;
            },
            deleteCookie(name) {
                this.setCookie(name, "", {
                    'max-age': -1
                })
            },
        },
        mounted() {
            this.isOpenedProfileModal = false
            // this.giveProfileInfo()
            
            this.mountedLang(this.$i18n.locale)
        },
        watch: {
        }
    }

</script>