<template>
    <header>
        <DevServer v-if="typeServer" :typeServer="typeServer" />
        <div class="container">
            <div class="header_links">
                <router-link :to="{ name: 'workboard-view'}" class="header_logo"><img :src="logoInHeader"
                        alt="adsquiz site logo"></router-link>
            </div>
            <transition name="fade" mode="out-in">
                <div class="header_buttons">
                    <template v-if="isAuthenticated">
                        <span v-tooltip="{
                                text: $t('leadsLeft'),
                                theme: {
                                    placement: 'bottom',
                                },
                            }" :class="['leads_left_counter', ledsCounterClassModificator, lemniscateClassCurrent]">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M15.24 4.76186C15.1272 4.66351 14.9877 4.6009 14.8393 4.58199C14.6908 4.56307 14.5401 4.58869 14.4062 4.65561L11.2681 6.21811L8.64812 1.86623C8.58077 1.75456 8.48571 1.66218 8.37216 1.59806C8.2586 1.53394 8.13041 1.50024 8 1.50024C7.86959 1.50024 7.74139 1.53394 7.62784 1.59806C7.51428 1.66218 7.41922 1.75456 7.35187 1.86623L4.73187 6.21998L1.595 4.65748C1.46146 4.59116 1.31131 4.56576 1.16339 4.58447C1.01547 4.60317 0.876369 4.66515 0.763543 4.76262C0.650717 4.86009 0.569193 4.98871 0.5292 5.13235C0.489208 5.27598 0.492529 5.42823 0.538747 5.56998L2.85125 12.655C2.8745 12.7262 2.91344 12.7912 2.96519 12.8453C3.01695 12.8994 3.0802 12.9412 3.15027 12.9676C3.22034 12.994 3.29545 13.0043 3.37004 12.9978C3.44463 12.9913 3.51681 12.9681 3.58125 12.93C3.59687 12.9206 5.195 12 8 12C10.805 12 12.4031 12.9206 12.4175 12.9294C12.482 12.9679 12.5543 12.9914 12.629 12.9981C12.7038 13.0049 12.7792 12.9947 12.8495 12.9683C12.9198 12.942 12.9832 12.9001 13.0352 12.8459C13.0871 12.7916 13.1261 12.7264 13.1494 12.655L15.4619 5.57186C15.5094 5.43006 15.5137 5.27733 15.4742 5.13309C15.4347 4.98885 15.3532 4.85963 15.24 4.76186ZM12.375 11.8019C11.625 11.4837 10.13 11 8 11C5.87 11 4.375 11.4837 3.625 11.8019L1.66937 5.81248L4.48625 7.21686C4.65675 7.30094 4.85256 7.31813 5.03511 7.26503C5.21765 7.21193 5.37369 7.0924 5.4725 6.92998L8 2.72873L10.5275 6.92873C10.6263 7.09099 10.7822 7.21042 10.9646 7.2635C11.147 7.31659 11.3427 7.29951 11.5131 7.21561L14.3306 5.81248L12.375 11.8019Z" />
                            </svg>
                            {{ availableLeadsCounter }}
                        </span>
                    </template>
                    <div class="header_languages">
                        <LanguageSelector @change-locale="changeLocale" />
                    </div>
                    <template v-if="isAuthenticated">
                        <button v-if="curentActiveTarif.price == 0" class="header_upversion"
                            @click="goToTariffs()"> <img src="/img/icons/upversion-star.svg" alt=""> {{
                            uprevisionButtonText}}</button>
                        <button v-else class="header_upversion" @click="goToTariffs()"> <img
                                src="/img/icons/upversion-star.svg" alt=""> {{ $t('getLeads')}}</button>
                        <button class="header_profile" @click="toggleProfileModal()">
                            {{ userSimbol }}
                        </button>
                    </template>
                </div>
            </transition>
        </div>
    </header>
</template>

<script>
import axios from 'axios'
import DevServer from '@/components/common/DevServer.vue'
import LanguageSelector from '@/components/common/LanguageSelector.vue'

export default {
  name: 'header-vue',
  components: {
    DevServer,
    LanguageSelector,
  },
  props: {
    isAuthenticated: Boolean,
  },
  data() {
    return {
        baseBackendUrl: process.env.VUE_APP_BASE_URL,
        lang: "UA",
        // opacityElement: 0,
        userInfo: {
            id: "17",
                email: "vasyayaya@mail.ru",
                name: "Jhonathan",
                role: "user",
                profile: "agency",
                tarifs: [{
                    id: "1",
                    name: "Free.",
                    price: 10,
                    usedLeads: 0,
                    maxLeadsCount: 100,
                    maxQuizzesCount: 0,
                    created_at: 1670528835,
                    is_active: true,
                    start_at: 1670528835,
                    end_at: 1670528835,
                }],
                tarif: {},
                period_starts_at: null,
                payments: [{
                    id: "6",
                    payment: "PT172022212_1670528631",
                    amount: 1,
                    created_at: 1670528835,
                    payment_system: "Stripe",
                    data: "{\"cardType\": \"Visa\"}",
                }],
                created_at: 1665096088,
                quizCounter: 0,
                leadCounter: 0,
                isBlocked: false,
                bonuses: [{
                    start: 1665096088,
                    end: 1665096088,
                    leads_count: 0,
                    used: 0,
                    coupon: '',
                    created_at: 1665096088,
                    updated_at: 1665096088,
                }],
        },
        isMobile: false,
    }
  },
  computed: {
    typeServer() {
          if (this.baseBackendUrl === "https://pre.adsquiz.io") {
            return "DEV Server"
          } else if (this.baseBackendUrl === "https://adsquiz-back-stage.quizads.tech") {
              return "Stage"
          } else if (this.baseBackendUrl === "https://adsquiz-back-qa.quizads.tech") {
              return "QA"
          } else {
            return null
          }
    },
    userSimbol() {
        return this.userInfo.name.slice(0, 1)
    },
    uprevisionButtonText() {
      return this.isMobile ? this.$t('upProVersionMobile') : this.$t('upProVersionDesktop');
    },
    logoInHeader() {
        return this.isMobile ? "/img/icons/adsquiz-logo-mobile.svg" : "/img/icons/adsquiz-logo.svg";
    },
    curentActiveTarif() {
        let lastActiveTariff = null;
        let activeTarifExists = false;
        let lastTarif = null;

        for (const tarif of this.userInfo.tarifs) {
            if (tarif !== null) {
                if (tarif.is_active) {
                    if (!lastActiveTariff || tarif.created_at > lastActiveTariff.created_at) {
                        lastActiveTariff = tarif;
                        activeTarifExists = true;
                    }
                }
                lastTarif = tarif;
            }
        }

        if (!activeTarifExists && lastTarif) {
            return lastTarif;
        } else if (lastActiveTariff) {
            return lastActiveTariff
        } else {
            return {
                id: "1",
                name: "Free.",
                price: 0,
                usedLeads: 0,
                maxLeadsCount: 100,
                maxQuizzesCount: 0,
                created_at: 1670528835,
                is_active: false,
                start_at: 1670528835,
                end_at: 1670528835,
                
            }
        }
    },
    availableLeadsCounter () {
        if (this.curentActiveTarif.maxLeadsCount >= 100000 && this.curentActiveTarif.is_active) {
            return "∞"
        } else {
            let availableleads = 0;

            for (const tarif of this.userInfo.tarifs) {
                if (tarif.is_active) {
                    availableleads += tarif.maxLeadsCount - tarif.usedLeads;
                }
            }
            if (this.userInfo.bonuses.length) {
                let bonuses_leads = 0
                this.userInfo.bonuses.forEach((bonus) => {
                    bonuses_leads += bonus.leads_count - bonus.used
                })
                return (availableleads + bonuses_leads)
            } else {
                return availableleads
            }
        }
    },
    lemniscateClassCurrent () {
        if (this.curentActiveTarif.maxLeadsCount >= 100000 && this.curentActiveTarif.is_active) {
            return "payment_plan_limit_lemniscate"
        } else {
            return ""
        }
    },
    ledsCounterClassModificator () {
        var availableleads = 0
        var maxLeadInTarrif = 0
        var bonuses_leads = 0
        for (const tarif of this.userInfo.tarifs) {
            if (tarif.is_active) {
                availableleads += tarif.maxLeadsCount - tarif.usedLeads;
                if (tarif.maxLeadsCount >= maxLeadInTarrif) {
                    maxLeadInTarrif = tarif.maxLeadsCount
                }
            }
        }
        if (this.userInfo.bonuses.length) {
            this.userInfo.bonuses.forEach((bonus) => {
                bonuses_leads += bonus.leads_count - bonus.used
            })
        }
        availableleads += bonuses_leads

        const percentage = (availableleads / maxLeadInTarrif) * 100
        const threshold1 = 20
        const threshold2 = 5
        var classArr = []
        // Проверяем условия
        if ((this.curentActiveTarif.maxLeadsCount >= 100000 && this.curentActiveTarif.is_active) || availableleads >= 100000) {
            classArr.push("blue_modificator")
        }

        if (percentage <= threshold2 || availableleads <= 0) {
            classArr.push("red_modificator")
        } else if (percentage <= threshold1) {
            classArr.push("orange_modificator")
        }

        return classArr
    },
  },
  methods: {
    goToTariffs() {
        this.$emit('go-to-tariffs', true);
    },
    toggleProfileModal() {
        this.$emit('togle-profile-modal', true);
    },
    refreshUserInfo() {
        this.$emit('refresh-user-info', this.userInfo);
    },
    changeLocale(locale) {
      this.$i18n.locale = locale
      localStorage.setItem('locale_lang', locale)
      this.mountedLang(this.$i18n.locale)
      if (this.isAuthenticated) {
        this.updateUserLang(this.$i18n.locale)
      }
    },
    changeLocaleAfterLogin(interface_lang) {
        this.$i18n.locale = interface_lang
    },
    updateUserLang(locale) {
        var data = JSON.stringify({
            query: `mutation {
                updateUser(
                    id: "` + this.userInfo.id + `",
                    interface_lang: "${locale}",
                )
                {
                    id
                    interface_lang
                }
            }`,
            variables: {}

        });

        var config = {
            method: 'post',
            url: this.baseBackendUrl + '/graphql/',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(() => {
                
            })
            .catch (error => {
                this.$notify({
                    title: 'Помилка в оновлені даних: "' + this.userInfo.email,
                    type: 'error'
                });
                console.log(error);
            })
    },
    giveProfileInfo() {
        var queryData = JSON.stringify({
            query: `query { 
            users (` + process.env.VUE_APP_USER_IDENT + `) {
                id
                email
                name
                role
                profile
                isBlocked
                interface_lang
                tarifs { 
                    id
                    name
                    price
                    usedLeads
                    maxLeadsCount
                    maxQuizzesCount
                    created_at
                    is_active
                    start_at
                    end_at
                }

                period_starts_at

                payments { 
                    id
                    payment_system
                    payment
                    amount
                    created_at
                    data
                }
                created_at
                quizCounter
                leadCounter
                isBlocked
                bonuses {
                    start
                    end
                    leads_count
                    used
                    coupon
                }
            }
        }`,
            variables: {}
        });

        var reciveQuizzes = {
            method: 'post',
            url: this.baseBackendUrl + '/graphql',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: queryData,
            withCredentials: true
        };

        axios(reciveQuizzes)
            .then(response => {
                this.userInfo = response.data.data.users[0]
                this.refreshUserInfo()
                // this.opacityElement = 1
                let locale_lang = this.userInfo.interface_lang ? this.userInfo.interface_lang.toUpperCase() : 'EN';
                const availableLocales = this.$i18n.availableLocales.map(locale => locale.toUpperCase());

                if (availableLocales.includes(locale_lang)) {
                    this.changeLocaleAfterLogin(locale_lang);
                } else {
                    this.changeLocaleAfterLogin('EN');
                }
                this.mountedLang(this.$i18n.locale)
            })
            .catch(error => {
                console.log(error);
                this.errorAuth(error.response.status)
            })
    },
    mountedLang(interfaceLang) {
        var htmlElement = document.getElementsByTagName('html')[0]
        htmlElement.setAttribute('lang', interfaceLang);
    },
  },
  created() {
    this.isMobile = window.innerWidth <= 680;
  },
  mounted() {
    if (this.isAuthenticated) {
       this.giveProfileInfo()
    }
  },
  watch: {

  }
};
</script>

<style scoped>


header {
    width: 100%;
    padding: 8px 28px;
    height: 64px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 100;
    background: var(--color-main-white);
    border-bottom: 1px solid var(--color-main-gray)
}
header>.container{
    max-width: 100%;
}

.header_section {
    padding: 30px;
}
header>.container>.header_links {
    width: 45%;
}
header>.container>.header_buttons {
    width: 75%;
}
@media screen and (max-width: 650px) {
    .header_in-ukraine {
        display: none;
    }
    header>.container>.header_links {
        width: 25%;
    }
    /* header>.container>.header_buttons {
        width: 75%;
    } */
}

.header_links {
    display: flex;
    align-items: center;
}

.header_to-site {
    color: #000;
    font-size: 40px;
    margin-right: 40px;
    font-weight: 900;
    line-height: 20px;
}

.header_to-site:hover {
    transition-duration: 0.3s;
    border-radius: 100px 20px 20px 100px;
    animation: backbtnmove 0.4s cubic-bezier(0, -0.28, 0.62, 1.21) infinite alternate;
}

.header_logo {
    margin-right: 20px;
    display: flex;
    align-items: center;
}

.header_in-ukraine {
    font-size: 14px;
    color: #000;
    font-weight: 700;
}

.header_buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
}

.leads_left_counter {
    padding: 6px 16px;
    border-radius: 40px;
    gap: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 6px;
}

.header_languages {
    display: flex;
}

.header_languages input {
    border: none;
    background: none;
    width: 32px;
    color: #000;
    font-weight: 300;
}

.header_languages input:hover,
.header_languages input:active,
.header_languages input:focus {
    font-weight: 800;
    outline: none;
    box-shadow: none!important;
}

.header_languages input.active {
    font-weight: 800;
}

.header_upversion {
    background: var(--color-main-blue);
    border: 1px solid var(--color-main-blue);
    color: var(--color-main-white);
    display: flex;
    align-items: center;
    padding: 12px 16px;
    border-radius: 8px;
    font-weight: 700;
    height: 40px;
}

.header_upversion img {
    height: 12px;
    width: 12px;
    margin-right: 8px;
}

.header_upversion:hover {
    cursor: pointer;
}

.header_profile {
    border: 1px solid #f3d5d5;
    background: #f3d5d5;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    border-radius: 100px;
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.header_profile:hover {
    transform: scale(1.05);
}


@media screen and (max-width: 680px) {
    header {
        height: auto;
        padding: 8px 16px;
    }
    .header_buttons {
        gap: 12px;
    }
    .leads_left_counter {
        padding: 6px 8px;
    }
    .header_upversion {
        height: auto;
        padding: 6px 8px;
    }
    .selected-language {
        padding: 6px 8px;
    }
    .header_profile {
        height: 32px;
        width: 32px;
    }
}

@media screen and (max-width: 520px) {
    header {
        padding: 8px;
    }
    .leads_left_counter {
        display: none;
    }
}

</style>
