<template>
    <section class="modal-section">
        <div class="disp-modal unsubscribe-modal-display cross-absolute" id="unsubscribe_modal">
            <div class="container">
                <div class="unsubscribe-modal">
                    <div class="modal-overlay" @click="closeModalUnsubscribe"></div>
                    <div class="modal-content-wrap unsubscribe-modal_wrap unsubscribe_m">
                        <div class="a-q-m_close" @click="closeModalUnsubscribe"></div>
                        <div class="unsubscribe_m_body">
                            <h4 class="header">{{ $t('subscriptionUnsubscribeModalTitle') }}</h4>
                            <p class="description">{{ $t('subscriptionUnsubscribeModalDescription') }}</p>
                            <div class="unsubscribe_m_inputs">
                                <input type="button" @click="closeModalUnsubscribe" class="btn-default-white" :value="$t('cancelAllAction')">
                                <input type="button" @click="cancelSubscription(unsubscribeTarifData.id)" class="btn-default-blue" :value="$t('confirmAllAction')">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from "axios"
export default {
    props: {
        unsubscribeTarifData: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            baseBackendUrl: process.env.VUE_APP_BASE_URL,
        }
    },
    methods: {
        closeModalUnsubscribe() {
            this.$emit("togle-unsubscribe-popup");
        },
        cancelSubscription(id) {
            var data = JSON.stringify({
                query: `mutation {
                    userCancelSubscription (subscription_id: "${id}")
                }`,
                variables: {}
            });

            var config = {
                method: 'post',
                url: this.baseBackendUrl + '/graphql/',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then(response => {
                    if (response.data.data.userCancelSubscription) {
                        this.$emit("complete-cancel-subscription");
                        this.closeModalUnsubscribe()
                    } else {
                        this.closeModalUnsubscribe()
                        this.$notify({
                            title: this.$t('subscriptionIsNotDeleteTitle'),
                            text: this.$t('subscriptionIsNotDeleteDescription'),
                            type: 'error'
                        });
                    }
                    console.log(response)
                })
                .catch(error => {
                    console.log(error);
                    this.errorAuth(error.response.status)
                });
        },
    },
    watch: {

    }
}
</script>

<style scoped>
.unsubscribe-modal {
    height: 100%;
    display: flex;
    align-items: flex-start;
    margin-top: 20%;
    justify-content: center;
}
.unsubscribe-modal_wrap {
    width: 95%;
    max-width: 396px;
    padding: 24px;
    background-color: var(--color-main-white);
    border-radius: 16px;
}
.unsubscribe_m_body {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.unsubscribe_m_inputs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    margin-top: 16px;
}
/* .unsubscribe_m_inputs input {
    width: auto;
} */
</style>