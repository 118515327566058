<template>
    <transition name="fade">
        <section class="modal-section">
            <div class="disp-modal aqa-modal cross-absolute section_restore_quiz_sended">
                <div class="container">
                    <div class="restore-quiz-sended-modal-wrap">
                        <div class="modal-overlay black" @click="closeRestoreQuizSendedModal()"></div>
                        <div class="modal-content-wrap restore-quiz-sended-modal-body restore-quiz-sended_m_b">
                            <div class="payment_popup_close cross-absolute" @click="closeRestoreQuizSendedModal()" id="close_lead_modal" @keyup.esc="closeRestoreQuizSendedModal()" @keyup.enter="closeRestoreQuizSendedModal()">
                            </div>
                            <div class="restore-quiz-sended_body">
                                <h3 class="restore-quiz-sended_title">{{ $t('restoreQuizSendedTitle') }}</h3>
                                <p><span class="restore-quiz-sended_important">{{ $t('restoreSuizSendedImportant') }}</span>: {{ $t('restoreQuizSendedDescription') }}</p>
                                <input class="btn-default-blue" type="button" :value="$t('ok')" @click="closeRestoreQuizSendedModal()">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </transition>
</template>

<script>
export default {
    data() {
        return {
        };
    },
    methods: {
        closeRestoreQuizSendedModal() {
            this.$emit('close-restore-quiz-sended-modal');
        }
    },
    mounted() {
    },
}
</script>

<style>
.restore-quiz-sended-modal-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.restore-quiz-sended-modal-body {
  width: 400px;
  margin: auto;
  height: auto;
  background-color: var(--color-main-white);
  padding: 24px;
  border-radius: 16px;
}

.restore-quiz-sended_body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}

.restore-quiz-sended_title {
    margin: 0 20px 8px 0;
}

.restore-quiz-sended_important {
  font-weight: 700;
}

  
</style>
  