<template>
  <footer>
    <section>
      <div class="container">
        <a class="footer_left">
          <p>AdsQuiz © 2022-</p>
          <span>{{ currentYear }}</span>
        </a>
        <div class="footer_right">
          <p>{{ $t('footerContactUs') }}</p>
          <a href="mailto:info@adsquiz.io">
            info@adsquiz.io
          </a>
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
export default {
  name: 'footer-vue',
  data() {
    return {
      currentYear: null
    };
  },
  mounted() {
    this.currentYear = new Date().getFullYear();
  },
};
</script>

<style scoped>
footer {
    padding: 12px 28px;
    height: 40px;
    position: relative;
    z-index: 100;
    background: var(--color-main-white);
    border-top: 1px solid var(--color-main-gray);
}

footer .container {
    display: flex;
    justify-content: space-between;
}

.footer_left {
    display: flex;
    align-items: center;
}

.footer_left p {
    width: auto;
    color: var(--color-main-dark-gray);
}

.footer_left span {
    width: auto;
    color: var(--color-main-dark-gray);
    font-weight: 300;
}

.footer_right {
    display: flex;
    align-items: center;
    color: var(--color-main-dark-gray);
}

.footer_right p {
    margin-right: 4px;
}

.footer_right a {
    color: var(--color-main-blue);
    font-weight: 700;
}

</style>
